.mobilePatrol-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 150px 150px 0px 150px;
    margin-bottom: 50px;
  }
  
  .mobilePatrol-left-content {
    transition: opacity 0.5s ease-in-out;
    padding-right: 20px;
  }
  
  .mobilePatrol-company-info {
    font-size: 16px;
    font-weight: 300;
    line-height: 2.3rem;
    font-family: "Montserrat", Sans-serif;
    text-align: justify;
    font-weight: 400;
  }
  
  .mobilePatrol-left-content h1 {
    font-size: 30px;
    font-weight: 600;
    padding-bottom: 20px;
  }
  
  .mobilePatrol-about-company-button {
    gap: 10px;
    padding: 0px 12px;
    border-radius: 4px 0 0 0;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 43.36px;
    text-align: left;
    background: #cb9803;
    color: white;
    margin-top: 30px;
    border: none;
    border-radius: 5px;
    display: flex;
  }
  
  .mobilePatrol-span-red {
    color: black;
  }
  
  .mobilePatrol-span-dark {
    font-weight: bold;
  }
  
  .mobilePatrol-button-class {
    margin-top: 50px;
  }
  
  .mobilePatrol-company-image {
    height: auto !important;
    width: auto;
    border-radius: 6px;
    padding: 0px 0px 50px 50px;
    /* border-radius: 150px 0px 150px 40px; */
  }
  
  .mobilePatrol-company-image img {
    height: auto;
  }
  
  .mobilePatrol-about-company-button .mobilePatrol-rightcircle-icon {
    width: 40px;
    height: 50px;
    margin-left: 12px;
  }
  
  .mobilePatrol-about-company-button:hover {
    background-color: white;
    color: #cb9803;
  }
  
  /* Media Queries */
  
  @media (max-width: 1200px) {
    .mobilePatrol-container {
      padding: 80px;
    }
  
    .mobilePatrol-left-content {
      padding-right: 15px;
    }
  
    .mobilePatrol-company-info {
      font-size: 20px;
      line-height: 40px;
    }
  
    .mobilePatrol-company-image,
    .mobilePatrol-right-content {
      width: 589px;
      padding: 0px 0px 30px 30px;
    }
  }
  
  @media (max-width: 1060px) {
    .mobilePatrol-container {
      flex-direction: column;
      padding: 20px 20px;
    }
  
    .mobilePatrol-left-content,
    .mobilePatrol-right-content {
      width: 100%;
    }
  
    .mobilePatrol-company-image,
    .mobilePatrol-right-content {
      padding: 0;
    }
  
    .mobilePatrol-company-image {
      width: 100%;
      height: auto;
      margin-top: 25px;
    }
  
    .mobilePatrol-company-info {
      padding-left: 15px;
    }
  }
  
  @media (min-width: 776px) and (max-width: 1240px) {
    /* Adjust button width if necessary */
  }
  
  @media (max-width: 768px) {
    .mobilePatrol-about-company-button {
      font-size: 1rem;
    }
  
    .mobilePatrol-company-info {
      font-size: 15px;
    }
  
    .mobilePatrol-company-image {
      height: auto !important;
       width: 100%;
       margin: 0 auto;
       margin-top: 30px;
    }
  }
  
  /* Override img and video styles */
  .mobilePatrol-container img,
  .mobilePatrol-container video {
    max-width: none; /* Override max-width */
    height: auto; /* Keep height auto */
  }
  
  @media (max-width: 700px) {
    .mobilePatrol-left-content h1 {
      text-align: center;
    }
  
    .mobilePatrol-about-company-button {
      margin: 0 auto;
      margin-top: 30px;
    }
  }
  