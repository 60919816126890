.hidden {
    display: none;
  }
  
  .sticky-button {
    position: fixed;
    bottom: 0.5%;
    right: 80px;
    border-radius: 50px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    z-index: 20;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    transition: 0.3s;
    transform: scale(1.3);
    
  }

  .sticky-button:hover {
    transform: scale(1.6);
    
}
  
  .sticky-button svg {
    margin: auto;
   
    fill: #c49804;
    width: 35px;
    height: 35px;
  }
  
  .sticky-button a,
  .sticky-button label {
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 55px;
    height: 55px;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }
  
  .sticky-button label svg.close-icon {
    display: none;
  }
  
  .sticky-chat {
    position: fixed;
    bottom: 0%;
    right: 5px;
    width: 320px;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    z-index: 21;
    opacity: 0;
    visibility: hidden;
  }
  

 
  .sticky-chat a {
    text-decoration: none;
    color: #505050;
  }
  
  .sticky-chat svg {
    width: 35px;
    height: 35px;
  }
  
  .sticky-chat .chat-content {
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25);
    overflow: hidden;
    font-weight: 400;
  }
  
  .sticky-chat .chat-header {
    position: relative;
    display: flex;
    align-items: center;
    padding: 15px 20px;
    background-color: #c49804;
    overflow: hidden;
  }
  
  .sticky-chat .chat-header:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 80px;
    height: 75px;
    background: rgba(0, 0, 0, 0.04);
    border-radius: 70px 0 5px 0;
  }
  
  .sticky-chat .chat-header svg {
    width: 35px;
    height: 35px;
    flex: 0 0 auto;
    fill: #fff;
  }
  
  .sticky-chat .chat-header .title {
    padding-left: 15px;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
  }
  
  .sticky-chat .chat-header .title span {
    font-size: 11px;
    font-weight: 400;
    display: block;
    line-height: 1.58em;
    margin: 0;
    color: #f4f4f4;
  }
  
  .sticky-chat .chat-text {
    display: flex;
    flex-wrap: wrap;
    margin: 30px 20px;
    font-size: 12px;
  }
  
  .sticky-chat .chat-text span {
    display: inline-block;
    margin-right: auto;
    padding: 10px;
    background-color: #f0f5fb;
    border-radius: 0px 15px 15px;
  }
  
  .sticky-chat .chat-text span:after {
    content: "just now";
    display: inline-block;
    margin-left: 2px;
    font-size: 9px;
    color: #989b9f;
  }
  
  .sticky-chat .chat-text span.typing {
    margin: 15px 0 0 auto;
    padding: 10px;
    border-radius: 15px 0px 15px 15px;
  }
  
  .sticky-chat .chat-text span.typing:after {
    display: none;
  }
  
  .sticky-chat .chat-text span.typing svg {
    height: 13px;
    fill: #505050;
  }
  
  .sticky-chat .chat-button {
    display: flex;
    align-items: center;
    margin-top: 15px;
    padding: 12px 20px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25);
    overflow: hidden;
    font-size: 12px;
    font-weight: 400;
  }
  
  .sticky-chat .chat-button svg {
    width: 20px;
    height: 20px;
    fill: #505050;
    margin-left: auto;
    transform: rotate(40deg);
    -webkit-transform: rotate(40deg);
  }
  
  .chat-menu:checked + .sticky-button label {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  
  .chat-menu:checked + .sticky-button label svg.chat-icon {
    display: none;
  }
  
  .chat-menu:checked + .sticky-button label svg.close-icon {
    display: table-cell;
  }
  
  .chat-menu:checked + .sticky-button + .sticky-chat {
    bottom: 90px;
    opacity: 1;
    visibility: visible;
  }
  

  /* For mobile devices */
@media (max-width: 767px) {
  .sticky-button {
    right: auto; /* Remove right positioning */
    left: 5px; /* Align to the left */
    bottom: 10px; /* Adjust bottom spacing */
    display: none;
  }
  
  .sticky-chat {
    right: auto; /* Remove right positioning */
    left: 5px; /* Align to the left */
    top: auto; /* Remove top positioning */
    bottom: 90px; /* Adjust bottom spacing */
    display: none;
  }
}
