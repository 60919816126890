.main-footer {
  padding: 50px 0px 20px 0px;
  font-weight: 500;
  background: linear-gradient(45deg, #cb9804 0%, #ab791c 50%, #cc890d 100%);
  color: #010101;
  background: linear-gradient(135deg, #cb9804, #d6a743, #a67c00, #6b4e00);
}

.container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  z-index: 1;
}

.footer-content {
  padding: 0 15px;
}

.footer-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

.footer-column {
  text-align: center; /* Center align content */
  flex: 1;
  min-width: 250px;
}

.logo-widget {
  text-align: center;
}

.logo-box {
  margin-bottom: 20px;
}

.logo-box img {
  max-width: 220px;
  margin: 0 auto;
}

.footer-title {
  font-size: 24px;
  margin-bottom: 20px;
  color: #000000;
}

.text p,
.text ul {
  margin-bottom: 10px;
  list-style: none;
  padding-left: 0;
  line-height: 40px;
  font-size: 18px;
  /* text-align: left; */
}

.footer-social {
  list-style: none;
  padding-left: 0;
 
  display: flex;
  justify-content: center; /* Center align social icons */
}

.footer-social li {
  display: inline-block;
  margin-right: 10px;
}

.footer-social li:last-child {
  margin-right: 0;
}

.footer-social li a {
  display: inline-block;
  width: 42px;
  height: 42px;
  line-height: 42px;
  background: #2e3138;
  color: #e3e3e3;
  text-align: center;
  border-radius: 50%;
}

.footer-social li a:hover {
  color: #ffffff;
  background: #cc9c04;
}

.footer-title li:hover {
  color: #fbf9f9;
}

.footer-bottom {
 
  padding: 20px 100px;
  
}

.footer-bottom-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footer-bottom-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.footer-bottom-column {
  flex: 1;
  min-width: 250px;
  text-align: center;
}

.footer-nav {
  list-style: none;
  padding-left: 0;
  text-align: right;
}

.footer-nav li {
  display: inline-block;
  margin-left: 20px;
}

.footer-nav li:first-child {
  margin-left: 0;
}

.footer-nav li a {
  color: #9ea0a9;
  text-decoration: none;
}

.list a:hover {
  color: #fffffe;
  text-decoration: none;
}

.text p:hover {
  color: #fffefb;
  text-decoration: none;
}

.footer-nav li a:hover {
  color: #ff5e14;
}

.list li {
  line-height: 30px;
  font-size: 20px;
}

.horizontal-list {
  display: flex;
  justify-content: flex-start; /* Align quick links to the left */
  gap: 20px; /* Add space between list items */
}

@media only screen and (max-width: 1000px) {
  .main-footer {
    font-size: 12px;
    font-weight: 300;
  }

  .footer-row {
    flex-direction: column;
    align-items: center;
  }

  .footer-column {
    margin-left: 0;
  }

  .footer-nav {
    text-align: center;
    margin-top: 15px;
  }

  .container {
    width: 100%;
  }

  .footer-title {
    font-size: 20px;
    margin-bottom: 20px;
    color: #e3e3e3;
  }

  .text p,
  .text ul {
    margin-bottom: 10px;
    list-style: none;
    padding-left: 0;
    line-height: 40px;
    font-size: 15px;
  }

  .list li {
    font-size: 15px;
    display: none;
  }

  .footer-bottom-content {
    flex-direction: column;
    align-items: center;
  }

  .horizontal-list {
    justify-content: center;
  }

  .footer-social {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 576px) {
  

  .footer-nav {
    text-align: center;
    margin-top: 15px;
  }

  .footer-nav li {
    display: block;
    margin: 10px 0;
  }
}
