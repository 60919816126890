/* Career2Comp.css */

.career2-container {
    display: flex;
    flex-direction: column; /* Change to column layout for smaller screens */
    width: 75%;
    margin: 0 auto;
}

.career2-left {
    display: flex;
    justify-content: center;
    align-items: center;
}

.career2-right {
    padding: 20px;
}

.career2-image {
    max-width: 100%;
    height: auto;
    padding: 10px; /* Reduce padding for smaller screens */
}

.career2-heading {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 20px;
    text-align: center; /* Center align heading for smaller screens */
}

.career2-form {
    display: flex;
    flex-direction: column;
}

.form-group {
    margin-bottom: 20px;
}

label {
    font-weight: bold;
}

.contact-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 5px;
}

.submit-button {
    width: 30%; /* Make button full width for smaller screens */
    padding: 10px;
    background-color: #cb9804;
    color: #fff;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submit-button:hover {
    background-color: orangered;
}

/* Media queries for responsiveness */
@media (min-width: 768px) {
    .career2-container {
        flex-direction: row; /* Switch back to row layout for larger screens */
    }

    .career2-left {
        flex: 1;
        order: 1;
    }

    .career2-right {
        flex: 1;
        padding: 20px;
        order: 2;
    }
}


@media (max-width: 768px) {
    
    .career2-left {
        flex: 1;
        order: 2;
    }

    .career2-right {
        flex: 1;
        padding: 20px;
        order: 1;
    }
    .submit-button {
      
        margin: 0 auto;
    }
    .career2-heading{
        font-size: 25px;
    }
    .career2-container {
       
        width: 90%;
        margin: 0 auto;
    }
}