/* Main container */
.cpws-main-wrapper {
    padding: 60px 100px;
    text-align: center;
    background-color: #f5f5f5;
  }
  
  /* Title container */
  .cpws-header-wrapper {
    margin-bottom: 40px;
  }
  
  .cpws-title {
    color: #000000;
    font-size: 50px;
    font-weight: 500;
  }
  
  .cpws-divider {
    display: block;
    width: 200px;
    height: 2px;
    background-color: #cb9804;
    margin: 20px auto 0;
  }
  
  /* Projects grid */
  .cpws-grid-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .cpws-grid-item {
    flex: 0 0 12%; /* Adjust the width of each grid item to fit 8 items per row */
    margin-bottom: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .cpws-image {
    width: 100%;
    height: auto;
    border-radius: 5px;
    object-fit: cover;
    background-color: white;
  }
  
  /* Media queries */
  @media (max-width: 1200px) {
    .cpws-main-wrapper {
      padding: 40px 50px;
    }
  }
  
  @media (max-width: 768px) {
    .cpws-grid-item {
      flex: 0 0 45%; /* 2 items per row */
      margin-bottom: 20px;
    }
  
    .cpws-title {
      font-size: 40px;
    }
  
    .cpws-divider {
      width: 150px;
    }
  }
  
  @media (max-width: 480px) {
    .cpws-grid-item {
      flex: 0 0 45%; /* 2 items per row */
      margin-bottom: 20px;
    }
  
    .cpws-title {
      font-size: 30px;
    }
  
    .cpws-divider {
      width: 100px;
    }
  }
  
  @media only screen and (min-width: 786px) and (max-width: 1000px) {
    .cpws-title{
      
    }
  }