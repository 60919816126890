/* Default styles */

.unit1-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 80px 150px 0px 150px;
  }
  
  .left-content {
  
    transition: opacity 0.5s ease-in-out;
    padding-right: 20px;
  }
  
  .company-info {
    font-size: 18px;
    font-weight: 300;
    line-height: 3rem;
    font-family: "Montserrat", Sans-serif;
   text-align: justify;
    font-weight: 400;

   
    
  }
  .left-content h1{
    font-size: 30px;
    font-weight: 600;
    padding-bottom: 20px;

  }
  
  .about-company-button {
    
    
    gap: 10px;
   padding: 0px 12px;
    border-radius: 4px 0 0 0;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
    font-size: 1rem;
    font-weight: 400;
    line-height: 43.36px;
    text-align: left;
    color: white;
    margin-top: 30px;
    border: none;
    border-radius: 5px;
    display: flex;
    border: 1px solid #cb9803;
    color: #cb9803;
   
  }
  
  
  .span-red{
    color: black;
  }
  .span-dark{
    font-weight: bold;
  }
  
  .button-class {
    margin-top: 50px;
  }
  
  
  .company-image {

    height: 423px !important;
    border-radius:6px;
    padding: 50px;
    border-radius: 150px 0px 150px 40px;
  }

  
  .about-company-button .rightcircle-icon {
    width: 40px;
    height: 50px;
    margin-left:12px;
  }
  .about-company-button:hover {
   
    background-color: white;
    color: #000000;
    border: #000000 1px solid;
    
  }
  
  
  /* Media Queries */
  
  /* @media (max-width: 1200px) {
    .unit1-container {
      padding: 80px;
    }
  
    .left-content {
      padding-right: 15px;
    }
  
    .company-info {
      font-size: 20px;
      line-height: 40px;
      
    }
  
    
    .company-image,
    .right-content {
      width: 589px;
      padding: 0px 0px 30px 30px;
    }
  }
   */
  @media (max-width: 1060px) {
    .unit1-container {
      flex-direction: column;
      padding: 20px 20px;
    }
  
    .left-content,
    .right-content {
      width: 100%;
    }
  
    .company-image,
    .right-content {
      padding: 0;
    }
  
    .company-image {
      width: 80%;
      margin: 0 auto;
     
      margin-top: 25px;
      display: flex;
      justify-content: center;
      
    }
  
   
    .company-info{
      padding-left: 15px;
    }
    
  }
  
  
  @media (min-width:776px) and (max-width:1240px){
    .unit1-container {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 80%;
      margin: 0 auto;
    }
    .unit1-container img{
     width: 60%;
     height: 300px !important;
    
    }

   
    
  }
  
  
  
  @media (max-width: 768px) {
    
    .about-company-button {
    
    
     
      font-size: 1rem;
     
      
     
    }
    .company-info{
      font-size: 15px;
      text-transform: none;
     
      line-height: 2.5rem;
      hyphens: auto;
      
    }
    .company-image {

        height: 200px !important;
        border-radius:6px;
        border-radius: 100px 0px 100px 0px;
      }
  }
  
  
  
  /* Home1Comp.css */

/* Override img and video styles */

/* Home1Comp.css */

/* Override img and video styles */
.unit1-container img,
.unit1-container video {
  max-width: none; /* Override max-width */
  height: auto; /* Keep height auto */
}

@media (max-width: 700px) {

  .left-content h1{
    text-align: center;
  }
  .about-company-button {
    
    
   margin: 0 auto;
    margin-top: 30px;
   
  }
  }
