.what-we-do-container {
    text-align: center;
    background-color: #121214;
    margin-top: 50px;
   
  }
  
  .title-container {
    margin-bottom: 40px;
  }
  
  .home2-title {
    color: #c49803;
    font-size: 60px;
    font-weight: 400;
    text-transform: none;
    padding-top: 40px;
  }
  .home2-mini-title{
    color: #fffffe;
    font-size: 25px;
    white-space: wrap;
    line-height: 50px;
  }
  
  .divider-separator {
    display: block;
    width: 200px;
    height: 2px;
    margin: 0 auto;
    background-color: #ffffff;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .grid-container {
    display: flex;
    justify-content: center;
    width: 70%;
    margin: 0 auto;
  }
  
  .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
  }
  
  .grid-item {
    text-align: center;
   
}

  
  .wwd-image {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    width: 500px; /* Set a fixed width */
    height: 300px; /* Set a fixed height */
    object-fit: cover;
  }
  
  .image-title {
    margin-top: 10px;
    padding: 20px 40px;
    border-radius: 5px;
    color: #c49803;
    font-family: "Montserrat",Sans-serif;
    font-size: 20px;
    font-weight: 300;
    text-transform: none;
    
  }
  
  @media (max-width: 768px) {
    .grid {
      grid-template-columns: repeat(1, 1fr);
    }
    .home2-title {
       
        font-size: 40px;
        
        
      }
      .home2-mini-title {
        color: #fffffe;
        font-size: 22px;
        white-space: wrap;
        line-height: 40px;
        font-weight: 100;
    }
  }
  