.whowe1-container {
  text-align: left;
  width: 80%;
  margin: 0 auto;
}




.whoweare-section {
  padding: 18px;
}

.whoweare-section h2 {

  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  letter-spacing: 0.02em;
  color: #000000;
  
}



.whowepara-section {
  border-left: 4px solid #cb9803;
  margin-top: 3%;
}

.whoweare-para {
  font-size: 18px;
  font-weight: 400;
  line-height: 40px;
  text-align: justify;
  color: black;
  margin-bottom: 18px;
}




.whoweare-para{
  padding-left: 3%;
}
.secondary-section {
  display: flex;
  flex-direction: column; 
  align-items: center; 
  width: 100%;
}

.side-images {
  display: flex;
  justify-content: space-between; 
  width: 100%;
  margin: 0 auto;
 
}

.side-image {
  width: 48%; 
  height: auto;
}



@media (max-width: 768px) {
  .whowe1-container {
    width: 95%;
  }
  .whoweare-section {
    padding: 10px;
  }

 

  
  .whoweare-section h2 {
    font-size: 28px;
    line-height: 35px;
  }

  .whoweare-para {
    font-size: 15px;
    line-height: 40px;
    margin-bottom: 15px;
  }

  .side-images {
    flex-direction: column; 
  }

  .side-image {
    width: 100%;
    margin-bottom: 15px;
  }
  .full-width-image{
    width: 100%;
  }
}



/* animation style */


@keyframes zoomer {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}

.zoomer-move {
  animation: zoomer 1s infinite alternate;
}




