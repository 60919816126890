.why-choose-us-container {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    width: 90%;
    margin: 0 auto;
}

.whychoose-image-container  {
    flex: 1;
    
    
}

.whychoose-img, .left-image{
    border-radius: 200px 0px 200px 0px;

}

.points-container {
    flex: 1;
    
}

.point {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.bullet-point {
    width: 20.94px;
    height: 20.19px;
    margin-right: 10px;
    color: #cb9803;
}

.point-text {
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0.02em;
    text-align: left;
    color: #000000;
    border-radius: 4px;
}

.learn-more-button {
    width: 160px;
    height: 42px;
    padding: 6px 0px;
    border-radius: 4px;
    border: 1px solid #cb9803;
    color:  #cb9803;
    cursor: pointer;
    box-sizing: border-box;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px;
    gap: 10px;
    margin-top: 30px;
}

.learn-more-button:hover {
   background-color: #cb9803;
   color: white;
}

.learn-more-icon{
    width: 20px;
    height: 20px;
}


/* Media Query for Mobile */
@media (max-width: 768px) {
    .why-choose-us-container {
        flex-direction: column;
        align-items: center;
        padding: 0px;
    }

    .whychoose-image-container,
    .points-container {
        width: 100%;
        margin-bottom: 20px;
        padding: 0px;
        margin-top: 20px;
        order: 1;
    }
    .whychoose-img{
        

    }
    .whychoose-image-container svg {
        width: 100%; 
        height: 200px;
        max-width: 100%; /* Ensures image doesn't exceed container width */
        max-height: 100%; 

    }

    .point {
        margin-bottom: 15px;
    }

    .point-text {
        font-size: 15px;
    }

    .learn-more-button {
        width: 100%;
        margin-top: 20px;
    }
    .whychoose-img, .left-image{
        border-radius: 100px 0px 100px 0px;
        height: auto;
        order: 2;
        padding: 0px 20px;
    
    }
    
}

/* Existing styles... */

/* New styles for bullet-image */
.bullet-image {
    flex: none; /* Prevent shrinking */
    margin-right: 10px;
  }
  
  /* Media Query for Mobile */
  @media (max-width: 768px) {
    /* ... Existing media query styles ... */
  
    .points-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start; /* Align items to start */
    }
  
    .point {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
    }
  
    .bullet-image {
      margin-right: 10px;
    }
  
    .point-text {
      font-size: 15px;
    }
  }
  
  @media (min-width:850px) and (max-width:992px){
   
    .left-image{
        width: 400px;
    }
    .whychoose-img, .left-image{
        border-radius:  0px;
    
    }
  }