.maps-container {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
}

.maps-mini-title {
    font-size: 30px;
    font-weight: 400;
    text-align: left;
    color: #cb8904;
    margin-bottom: 10px;
}

.maps-heading {
    font-size: 35px;
    font-weight: 900;
    line-height: 50.73px;
    letter-spacing: 0.02em;
    text-align: left;
    color: black;
    margin-bottom: 20px;
}

.maps-frame {
    width: 100%;
    height: auto;
}

/* Responsive styles */
@media (max-width: 1024px) {
    .maps-container {
        padding: 15px;
    }

    .maps-heading {
        font-size: 24px;
    }

    .maps-frame iframe {
        height: 400px;
    }
}

@media (max-width: 768px) {
    .maps-container {
        padding: 10px;
    }

    .maps-heading {
        font-size: 20px;
    }

    .maps-frame iframe {
        height: 350px;
    }
}

@media (max-width: 576px) {
    .maps-container {
        padding: 5px;
    }

    .maps-heading {
        font-size: 18px;
    }

    .maps-frame iframe {
        height: 300px;
    }
}
