/* Custom styles for main1Comp component */

.mainCareer-section {
    margin: 0 auto;
    width: 100%;
    height: 400px; /* Set the height of the banner */
    background-image: url('../assets/careerBanner.jpg'); /* Adjust the path according to your project structure */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center; /* Center vertically */
    position: relative; /* Make the position relative for absolute positioning */
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the transparency to make it dimmer */
    z-index: 0; /* Ensure the overlay is behind other elements */
}

.mainCareer-title {
    padding: 0;
    margin: 0;
    font-size: 60px;
    color: rgb(255, 255, 255); /* Set text color to white */
    text-align: left; /* Align to the left */
    text-transform: uppercase;
    line-height: 1;
    font-weight: bold;
    border-left: 10px solid #cc9c04;
    padding-left: 40px;
    position: relative; /* Set the position to relative to ensure it's above the overlay */
    z-index: 1; /* Ensure the text is above the overlay */
}

.main-content {
    padding: 20px;
    text-align: left;
}

.main-title-Careersection {
    width: 73%;
    margin: 0 auto;
}

@media only screen and (max-width: 768px) {
    .main-title {
      font-size: 50px; /* Adjust the font size for small devices */
     
    }
    .main-title-Careersection {
        width: 80%;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .main-section {
        margin: 0 auto;
        width: 100%;
        height: 200px; /* Set the height of the banner */
      
    }
    
  }
  