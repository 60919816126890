.career-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 100px 20px 50px 20px;
    width: 75%;
}

.career-content {
    text-align: center;
}

.career-main-heading {
    font-size: 35px;
    font-weight: 700;
    margin-bottom: 20px;
    color: #cb9804;
}

.career-message {
    font-size: 20px;
    line-height: 1.5;
}

.career-message a {
    color: #cb9804;
    text-decoration: none;
}

.career-message a:hover {
    text-decoration: underline;
}

@media (max-width: 576px) {
    .career-main-heading {
        font-size: 25px;
    }

    .career-message {
        font-size: 18px;
    }
}
