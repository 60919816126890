.why-hts-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 83%;
    margin: 0 auto;
    padding: 0px 20px;

    margin-top: 50px;
  }
  .why-hts-container h1{
    font-size: 30px;
    padding:30px 0px;
    font-weight: 600;
  }
  
  .image-container {
    width: 100%; /* Adjusted to make it responsive */
    max-width: 800px; /* Maximum width for desktop */
  }
  
  .image-container svg {
    width: 100%;
    height: auto;
  }
  
  .content-container {
   
    font-size: 18px;
    font-weight: 400;
    text-align: left;
    color: #000000;
    word-spacing: 3px;
    line-height: 3rem;
  }
  
  .content-section {
    padding-left: 20px;
    border-left: 3px solid #cb9803;
}
  
  @media (max-width: 768px) { /* Media query for mobile screens */
    .why-hts-container {
      width: 100%; 
      padding: 0px 10px; 
    }
  
    .image-container {
      width: 100%; 
      max-width: none; 
    }
  
    .content-container {
      font-size: 15px; 
      line-height: 2.5rem;
    }
    .content-section{
        margin-left: 12px;
    }
    .why-hts-container h1 {
      font-size: 25px;
      padding: 0px 0px;
      padding-bottom: 20px;
      font-weight: 600;
      text-align: center;
      
  }
  }
  