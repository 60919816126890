@keyframes typing {
    from { width: 0; }
    to { width: 100%; }
  }
  
  .typing::after {
    content: "|";
    display: inline-block;
    width: 0;
    animation: typing 3s steps(30, end) infinite;
  }
 
.background-image {
  /* background-image: url('https://tse2.mm.bing.net/th?id=OIP.IMX0KS-8ZpedCS3cJHdz7AHaEK&pid=Api&P=0&h=180'); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: auto; 
  width: 100%;
 background-color: #c49804;
}


@media (max-width: 1000px) {
  .brand-image, .brandmini-heading, .brand-heading , .brand-icons , .hide-it{
    display: none;

  
  }
  .brandbar-section{
    background-color: rgb(158, 157, 155);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color:rgb(0, 0, 0);
    color: #e3e3e3;
  }
  .brandbar-end{
     }
     .brandbar-middle{
      display: flex;
      justify-content: center;
      align-items: center;
     }
}


