.other-services-container {
    width: 75%;
    margin: 0 auto;
    padding: 50px 20px;
  }
  
  .service-section {
    margin-bottom: 30px;
  }
  
  .service-section h2 {
    font-size: 24px;
    color: #cb9804;
    margin-bottom: 15px;
  }
  
  .service-section p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 10px;
    color: #333;
  }
  
  @media (max-width: 768px) {
    .other-services-container {
      width: 90%;
      padding: 30px 10px;
    }
  
    .service-section h2 {
      font-size: 22px;
    }
  
    .service-section p {
      font-size: 14px;
    }
  }
  