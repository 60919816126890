.client-container {
    text-align: center;
    margin-bottom: 50px;
    width: 80%;
    margin: 0 auto;
    padding: 100px;
    padding-bottom: 100px;
}

.client-mini-title {
    font-size: 25px;
    font-weight: 600;
    color: #cb9804;
}

.client-heading {
    font-size: 35px;
    font-weight: 700;
    margin-top: 10px;
    margin-bottom: 30px;
    color: #000;
    padding-top: 40px;
}

.client-grid {
    display: grid;
    grid-template-columns: repeat(4, 2fr);
    grid-gap: 20px;
    justify-content: center;
    align-items: center;
}

.client-item {
    text-align: center;
    padding: 10px; /* Add padding for spacing */
}

.client-logo {
    max-width: 100%;
    height: auto;
    display: block; /* Center the image horizontally */
    margin: 0 auto; /* Center the image vertically */
}

@media (max-width: 768px) {
    .client-grid {
        grid-template-columns: repeat(2, 1fr);
    }
    .client-container {
      
        padding: 0px;
        margin-top: 40px;
    }
    .client-heading{
        padding-top: 10px;
        font-size: 25px;
    }
}
