.hero-section {
    position: relative;
    width: 100%;
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
  }
  
  .hero-section::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0); /* Semi-transparent black overlay */
    z-index: 1; /* Ensure it covers the background image but below the content */
  }
  
  .hero-content {
    position: relative;
    text-align: center;
    z-index: 2; /* Ensure content is above the overlay */
  }
  
  .integrated-facility-services {
    color: #f6f5f4;
    font-weight: 400;
    letter-spacing: 1px;
    font-size: 40px;
  }
  
  .southern-cross-group {
    color: #cc9c04;
    font-size: 70px;
    font-weight: 900;
    text-transform: uppercase;
    line-height: 1.4em;
    margin-top: 50px;
  }
  
  .about-us-button {
    background-color: #060644;
    border: none;
    border-radius: 4px;
    color: #007bff;
    font-size: 18px;
    padding: 15px 60px;
    cursor: pointer;
    margin-top: 50px;
  }
  .about-us-button:hover{
    color:#919191 ;
  }
  
  .work-info {
    position: absolute;
    bottom: 20px;
    left: 20px;
    color: #fcfcfc;
    color: #0f0fd8d1;
    font-size: 30px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1.4em;
  }
  
  .changing-line {
    animation: changeLine 5s infinite alternate;
  }
  
  @keyframes changeLine {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
  /* For mobile devices */
  @media only screen and (max-width: 600px) {
    .hero-section {
      position: relative;
      width: 100%;
      height: 80vh;
    }
     
    .integrated-facility-services {
      font-size: 32px;
      font-weight: 400;
    }
    
    .southern-cross-group {
      font-size: 36px;
      font-weight: 600;
      
    }
  
    .about-us-button {
      font-size: 14px;
      padding: 10px 20px;
    }
  
    .work-info {
      font-size: 20px;
    }
  }
  
  /* For tablets */
  @media only screen and (min-width: 601px) and (max-width: 1024px) {
    .integrated-facility-services {
      font-size: 40px;
    }
    
    .southern-cross-group {
      font-size: 60px;
    }
  
    .about-us-button {
      font-size: 16px;
      padding: 12px 25px;
    }
  
    .work-info {
      font-size: 25px;
    }
  }
  
  