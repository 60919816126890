@media only screen and (min-width: 768px) {
    .faq-section{
        width: 70%;
        margin: 0 auto;
    }
    .faq-que{
        font-weight: 1000px;
    }


}
