.navbar-full-width {
  width: 100%;
  padding: 0;
  background: #ffffff;
  box-shadow: 0px 4px 4px 0px #00000040;
  font-family: 'Montserrat', sans-serif !important;
  padding: 10px 0px !important;
  text-transform: uppercase;
  color: black;
}

.navbar-full-width {
  position: sticky;
  top: 0;
  z-index: 1000; 
}

.p-0 {
  position: sticky !important;
}

.navbar-container {
  display: flex;
  justify-content: flex-start; /* Align items to the start */
  align-items: center;
  padding: 0 20px; /* Add some padding for spacing */
}

.navbar-nav {
  display: flex;
  flex: 1; /* Take up remaining space */
  justify-content: space-around; /* Distribute items evenly */
}

.navbar .nav-link {
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 39.65px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #070707 !important; /* Default text color */
  position: relative; /* Required for ::after positioning */
  white-space: nowrap;
        font-size: 15px;
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: 0.8px;
}

.navbar .nav-link.active {
  font-family: 'Montserrat', sans-serif !important;
  font-size: 15px;
  font-weight:600;
  line-height: 39.65px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #cc9c04 !important;
  
}
  


.nav-link.no-underline {
  text-decoration: none !important;
}

.navbar .nav-link.active::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -3px;
  width: 100%;
  height: 2px;
  text-decoration: none;
}

.navbar .navbar-toggler {
  border: 1px solid black;
  outline: none !important;
}

.navbar-toggler-icon {
  background-color: #ffffff;
}

.custom-dropdown .dropdown-menu {
  border: none; 
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); 
  border-radius: 0; 
}

.custom-dropdown .dropdown-item {
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 30.65px;
  letter-spacing: 0.02em;
  text-align: left;
  background-color: #fdfdfd; /* Background color */
  color: #050505; /* Text color */
  border-top: 1px solid #070707; /* Border between dropdown items */
}
.custom-dropdown.active > .nav-link,
.custom-dropdown .dropdown-item.active {
  /* background-color: #f7f7f7;  */
  color: #cc9c04 !important; 
 
}
.custom-dropdown .dropdown-item:hover,
.custom-dropdown .dropdown-item:focus {
  background-color: #919191; /* Hover and focus background color */
}

/* Rotate dropdown indicator when dropdown is open */
.navbar .nav-link.dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent;
  transition: transform 0.3s ease-in-out; /* Transition for smooth rotation */
  
}

.navbar .nav-link.dropdown-toggle[aria-expanded="true"]::after {
  transform: rotate(180deg); /* Rotate the dropdown indicator when dropdown is open */
}
.navbar .nav-link.dropdown-toggle[aria-expanded="true"]::after {
  transform: rotate(180deg);
}


/* Custom styles for active state of main menu */
.custom-dropdown.active > .nav-link {
  color: #cc9c04;
  
}

/* Custom styles for active state of submenu items */
.custom-dropdown .dropdown-item.active {
  background-color: #f7f7f7; 
  color: #cc9c04; 
  

}



.navbar .navbar-toggler {
  font-size: 30px;
  color: #000000;
  border: none;
}

.navbar .navbar-toggler:focus {
  box-shadow: none; 
}

.navbar .navbar-toggler:active {
  box-shadow: none; 
}

/* Default styles for dropdown menu */
.custom-dropdown .dropdown-menu {
  border: none; 
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); 
  border-radius: 0; 
  display: none; /* Hide dropdown menu by default */
}

/* Styles for hover effect on desktop and large devices */
@media (min-width: 992px) { /* Adjust breakpoint as per your design */
  .custom-dropdown:hover .dropdown-menu {
    display: block; /* Display dropdown menu on hover */
  }
}

@media (max-width: 1000px) {
  .navbar .nav-link {
    font-size: 13px;
  }
  .custom-dropdown .dropdown-item {
    font-family: 'Montserrat', sans-serif !important;
    font-size: 13px;
  }
  .navbar-full-width {
  
    background: #cc9c04 !important;
   
    color: black;
  }
  .navbar .nav-link.active {
   
    color: #e3e3e3 !important;
    
  }
  .custom-dropdown.active > .nav-link {
    color: #f6f6f6;
  }
  
  .custom-dropdown .dropdown-item.active {
    background-color: #000000; 
    color: #f5f4f4; 
  }
  .custom-dropdown .dropdown-item {
   
    background-color: #f9f9f9; /* Background color */
    color: #000000; /* Text color */
  
  }
  
  .custom-dropdown .dropdown-item:hover,
  .custom-dropdown .dropdown-item:focus {
    background-color: #919191; /* Hover and focus background color */
  }
  .custom-dropdown.active > .nav-link,
.custom-dropdown .dropdown-item.active {
  /* background-color: #f7f7f7;  */
  color: #fdfcfc !important; 
}
}





.navbar-collapse {
  transition: max-height 0.3s ease-in-out;
}

.custom-dropdown .dropdown-menu {
  display: none; /* Hide by default */
  visibility: hidden; /* Ensure it's not visible */
  opacity: 0; /* Fully transparent */
  transition: visibility 0s, opacity 0.3s ease-in-out;
}

.custom-dropdown.show .dropdown-menu {
  display: block; /* Show the dropdown */
  visibility: visible; /* Make it visible */
  opacity: 1; /* Fully opaque */
}

.navbar-collapse {
  transition: max-height 0.3s ease-in-out;
}

.custom-dropdown .dropdown-menu {
  display: none; /* Hide by default */
  visibility: hidden; /* Ensure it's not visible */
  opacity: 0; /* Fully transparent */
  transition: visibility 0s, opacity 0.3s ease-in-out, max-height 0.3s ease-in-out;
  max-height: 0;
  overflow: hidden;
}

.custom-dropdown.show .dropdown-menu {
  display: block; /* Show the dropdown */
  visibility: visible; /* Make it visible */
  opacity: 1; /* Fully opaque */
  max-height: 500px; /* Adjust as needed for content height */
}