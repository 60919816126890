.whowe3comp-wrapper {
  width: 100%;
  margin-top: 50px;
}

.whowe3comp-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 20px;
  width: 80%;
  margin: 0 auto;
}

.whowe3comp-quality-image {
  width: 44%;
  height: 400px;
  overflow: hidden;
  position: relative;
}

.whowe3comp-quality-image .image-overlay {
  position: absolute;
  background-color: #eeeeee;
  width: 70%;
  height: 70%;
  top: 0;
  right: 0; /* Changed from left: 0; to right: 0; */
  z-index: 1;
}

.whowe3comp-quality-image .quality-image {
  position: relative;
  z-index: 2;
  width: 90%;
  height: 55%;
  object-fit: contain;
  top:7%;
}

.whowe3comp-quality {
  width: 65%;
}

.quality-heading {
  font-size: 30px;
  font-weight: 600;
  line-height: 72.27px;
  letter-spacing: 0.14em;
  text-align: left;
  width: 72%;
  height: 52px;
  margin-bottom: 20px;
  color: #000000;
}

.quality-points {
  list-style-type: none;
  padding-left: 0;
  text-align: justify;
}

.quality-points li {
  font-size: 17px;
  font-weight: 400;
  line-height: 31px;
  letter-spacing: 0.02em;
  text-align: left;
  position: relative;
  
  margin-bottom: 15px;
  text-align: justify;
  color: #000000;
}



/* Mobile Responsive Styles */
@media (max-width: 768px) {
  .whowe3comp-wrapper {
    margin-top: 10px;
  }
  .whowe3comp-content {
    flex-direction: column;
  }

  .whowe3comp-quality,
  .whowe3comp-quality-image {
    width: 100%;
    max-width: 100%;
    height: auto; /* Changed height to auto for responsiveness */
    margin-bottom: 20px;
  }

  .whowe3comp-quality-image {
    order: 2;
  }

  .whowe3comp-quality {
    order: 1;
  }

  .whowe3comp-quality-image .image-overlay {
    height: 80%; /* Adjusted height for mobile screens */
    width: 70%; /* Adjusted width for mobile screens */
    
    left: auto; /* Reset left position for mobile screens */
    right: 0; /* Aligns the image-overlay to the right */
}


  .whowe3comp-quality-image .quality-image {
    height: 100%;
    width: 80%;
    left: 9%;
    top: 30px;
    margin-right: auto;
    margin-left: 0;
    z-index: 1;
}

  .quality-heading {
    text-align: center;
    width: 100%;
    font-size: 26px;
  }
  .quality-points li {
    font-size: 15px;
    hyphens: auto;
   
  }
  

 
}




@media (min-width: 769px) and (max-width: 1400px) {
  .whowe3comp-wrapper {
    width: 100%;
  }
  
  .whowe3comp-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 40px;
    width: 84%;
    margin: 0 auto;
  }
  
  .whowe3comp-quality-image {
    width: 44%;
    height: 400px;
    overflow: hidden;
    position: relative;
  }
  
  .whowe3comp-quality-image .image-overlay {
    position: absolute;
    background-color: #eeeeee;
    width: 70%;
    height: 80%;
    top: 0;
    right: 0; /* Changed from left: 0; to right: 0; */
    z-index: 1;
  }
  
  .whowe3comp-quality-image .quality-image {
    position: relative;
    z-index: 2;
    width: 80%;
    height: 55%;
    object-fit: contain;
    top:11%;
    left: 50px;
  }
  
  .whowe3comp-quality {
    width: 65%;
  }
  
  .quality-heading  {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 72.27px;
    letter-spacing: 0.14em;
    text-align: left;
    width: 72%;
    height: 72px;
   
  }
  
  .quality-points {
    list-style-type: none;
    padding-left: 0;
  }
  
 
  
  
  
}  