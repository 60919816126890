.why-hts-wrapper {
  width: 100%;
}

.why-hts-image {
  width: 100%;
  height: auto;
  overflow: hidden;
}

.why-hts-image img {
  width: 100%;
  height: auto;
  object-fit: contain;
}


.why-hts-blog {
  border-left: 3px solid #E00006;
  padding-left: 20px;
}

.why-hts-para {
  font-size: 20px;
  font-weight: 400;
  line-height: 47.58px;
  letter-spacing: 0.04em;
  text-align: justify;
  color: rgba(103, 96, 96, 1);
  margin-bottom: 20px;
}

.why-hts-points {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 78%;
  margin: 0 auto;
  margin-top: 3rem;
  align-items: flex-start;
}
.why-hts-points h2{
  font-size: 20px;
  text-align: justify;
  line-height: 50px;
  font-weight: 400;
}
.why-hts-points h1{
  font-size: 30px;
  text-align: justify;
  line-height: 50px;
  font-weight: 600;
}

.why-hts-point {
  display: flex;
  align-items: flex-start; /* Align items at the start */
}

.check-icon {
  font-size: 30px;
  color: #CB9803; /* Adjust color as needed */
  margin-top: 10px; /* Adjust margin to align with the top of the text */
  margin-right: 20px;
}

.why-hts-point-text {
  font-size: 18px;
  font-weight: 400;
  line-height: 47.58px;
  letter-spacing: 0.02em;
  text-align: justify;
  color: #000000;
  align-self: flex-start; /* Align text at the start */
}

/* Mobile Responsive Styles */
@media (max-width: 768px) {
  .why-hts-image {
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f4f4f4; 
  }
  
  .why-hts-image img {
    object-fit: contain;
    max-height: 100%;
    max-width: 100%;
    height: auto;
    width: auto;
    margin-top: 5px;
  }

  .why-hts-para,
  .why-hts-point-text {
    font-size: 16px;
    line-height: 35.64px;
  }

  .why-hts-points {
    display: block; /* Change back to block for mobile */
    width: 100%;
  }

  .why-hts-point {
    margin-bottom: 20px; /* Space between each point */
  }

  .why-hts-point-text {
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0.02em;
    text-align: justify;
   
    
  }
  .check-icon{
    margin-right: 10px;
  }
  .why-hts-points h2{
    line-height: 40px;
    margin-bottom: 30px;
  }
}
