.customer-reviews-container {
    padding: 50px 20px;
    
    text-align: center;
    width: 80%;
    margin: 0 auto;
  }
  
  .reviews-title {
    font-size: 2.5rem;
    margin-bottom: 40px;
    color: #343a40;
  }
  
  .reviews-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
  }
  
  .review-card {
    background: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    padding: 30px 20px;
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
  }
  
  .review-text {
    font-size: 1rem;
    color: #495057;
    margin: 20px 0;
    position: relative;
    padding: 0 20px;
  }
  
  .quote-icon {
    font-size: 1.5rem;
    color: #66fcf1;
    position: absolute;
  }
  
  .left {
    top: 10px;
    left: 10px;
  }
  
  .right {
    bottom: 10px;
    right: 10px;
  }
  
  .review-author {
    font-size: 1rem;
    font-weight: bold;
    color: #343a40;
    margin-top: 20px;
  }
  
  .review-position {
    font-size: 0.9rem;
    color: #868e96;
  }
  
  @media (min-width: 768px) {
   
    .reviews-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 767px) {
    .customer-reviews-container {
       
        width: 90%;
        margin: 0 auto;
      }
    .review-card {
      width: 100%;
    }
  }
  